export const NEXT_PUBLIC_GRAPHQL = process.env.NEXT_PUBLIC_GRAPHQL;
export const NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEXNAME =
  process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEXNAME;
export const NEXT_PUBLIC_ALGOLIA_APP_ID =
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID;
export const NEXT_PUBLIC_DESIGNER_ROLE = process.env.NEXT_PUBLIC_DESIGNER_ROLE;
export const NEXT_PUBLIC_UPLOADER_ROLE = process.env.NEXT_PUBLIC_UPLOADER_ROLE;
export const NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY =
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY;
export const NEXT_PUBLIC_CRYPTO_SECRET_KEY =
  process.env.NEXT_PUBLIC_CRYPTO_SECRET_KEY;
export const NEXT_PUBLIC_DIRECT = process.env.NEXT_PUBLIC_DIRECT;
export const NEXT_PUBLIC_MASK_FOLDER_ID =
  process.env.NEXT_PUBLIC_MASK_FOLDER_ID;
export const NEXT_PUBLIC_TYPESENSE_SEARCH_KEY =
  process.env.NEXT_PUBLIC_TYPESENSE_SEARCH_KEY;
export const NEXT_PUBLIC_TYPESENSE_CLUSTER_NAME =
  process.env.NEXT_PUBLIC_TYPESENSE_CLUSTER_NAME;
