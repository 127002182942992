import WavesImage from '@/images/homepage/waves.svg';
import CircleImage from '@/images/homepage/circle-gradient.svg';
import { ReactSVG } from 'react-svg';
import { useEffect, useState } from 'react';
import ApiService from 'services/ApiService';
import { toast } from 'react-toastify';
import { getMediaUrl } from '@/datalayer/config';

const PartnersBlock = () => {
  const [partners, setPartners] = useState([]);

  const fetchPartners = async () => {
    try {
      const partnersResponse =
        await ApiService.instance.get('/api/getPartners');
      setPartners(partnersResponse?.data?.data?.Partners?.[0]?.partners || []);
    } catch (error) {
      console.error(error);
      toast.error('Error fetching partners.');
    }
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  if (!partners || partners.length === 0) return null;

  return (
    <>
      <div className="partners-block p-100" id="partners">
        <div className="container">
          <div className="text-container">
            <h4>
              Our <span className="partners-text-container">Partners</span>
            </h4>
          </div>
          <div className="partners-container">
            <div className="partners-left-icons-container">
              <ReactSVG src={CircleImage.src} />
              <ReactSVG src={WavesImage.src} />
            </div>
            <div className="partners-right-icons-container">
              <ReactSVG src={WavesImage.src} />
            </div>
            <div className="image-container">
              <div className="partners-card">
                {partners.map((partner) => (
                  <div
                    key={partner?.Partner_logos_id?.name}
                    className="card-image"
                  >
                    <img
                      src={getMediaUrl(partner?.Partner_logos_id?.logo?.id)}
                      alt={`${partner?.Partner_logos_id?.name} Logo`}
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersBlock;
